import React from "react";
import { object } from "prop-types";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Layout } from "components/Layout";

import { errorText, mainContainer, icon } from "./styles.module.scss";

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <div className={mainContainer}>
      <FontAwesomeIcon icon={faExclamationTriangle} className={icon} />
      <div className={errorText}>404 - nie znaleziono szukanej strony</div>
    </div>
  </Layout>
);

export default NotFoundPage;

NotFoundPage.propTypes = {
  location: object.isRequired,
};
